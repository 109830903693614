import Layout from '../components/layout';
import { Link } from 'gatsby';
import React from 'react';
import SEO from '../components/seo';

const AboutPage = ({ location }) => (
  <Layout bgOpacity={0.8} location={location}>
    <SEO title="About" keywords={['about', 'about me']} />

    <div
      className="font-serif max-w-md mx-auto text-grey-600 px-4 rounded"
      style={{ lineHeight: '1.7' }}
    >
      <p className="md:border-l-4 md:border-grey-800 md:text-justify md:pl-4">
        I'm Joey Defourneaux, a web developer and engineer from{' '}
        <a
          href="https://www.huntsville.org"
          className="gradient-underline whitespace-no-wrap"
        >
          Huntsville
        </a>
        . I craft excellent websites and provide a small group of clients with
        direct and personal web services.
      </p>
      <p className="md:text-justify md:pl-4">
        I’ve built everything from small personal websites to full enterprise
        applications serving thousands of customers. I specialize in Python,
        Django, Angular, React, and HTML/CSS/JS, but picking up a new language
        or framework is never an issue.
      </p>
      <p className="md:text-justify md:pl-4">
        More about me? I love to create. Whether its code for the web or
        furniture in the woodshop, I love to make something from scratch. When
        I'm not coding, I'm with my beautiful wife and son. Helping build a
        family is the best creating I have done yet.
      </p>
      <p className="md:text-justify md:pl-4">
        Want to work together or have me build something for you?{''}
        <Link to="/contact/" className="gradient-underline whitespace-no-wrap">
          Get in touch
        </Link>
        !
      </p>
    </div>
  </Layout>
);

export default AboutPage;
